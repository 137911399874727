import React, { useState } from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { LogoColorBlock } from "../../svgs";
import {
  FacebookLogo,
  InstagramLogo,
  TwitterLogo,
  TwitchLogo,
  YoutubeLogo,
} from "../../svgs/social";

import "./page-footer.scss";
import { TrackedLink } from "..";

const MAIN_LINK_FILLS = ["#6e4aff", "#1cb3bd", "#9c42ab", "#d2c867"];

const PageFooter = () => {
  const [currFillIndex, setCurrFillIndex] = useState(0);

  const handleMainLinkMouseEnter = () => {
    const newColor =
      currFillIndex >= MAIN_LINK_FILLS.length - 1 ? 0 : currFillIndex + 1;
    setCurrFillIndex(newColor);
  };

  return (
    <footer className="page-footer partie-block">
      <div className="partie-block__content">
        <nav className="page-footer__nav">
          <div className="page-footer__nav-column">
            <h1 className="page-footer__title">Product</h1>
            <ul className="page-footer__nav-list">
              <li>
                <TrackedLink type="page-footer__link" to="/" children="About" />
              </li>
              <li>
                <TrackedLink type="page-footer__link" to="/features" children="Features" />
              </li>
              <li>
                <TrackedLink type="page-footer__link" to="/plus" children="Partie Plus" />
              </li>
              <li>
                <TrackedLink rel="noreferrer" type="page-footer__link" to="https://app.partie.com" children="Log In" />
              </li>
              <li>
                <TrackedLink type="page-footer__link" to="/download" children="Download" />
              </li>
            </ul>
          </div>

          <div className="page-footer__nav-column">
            <h1 className="page-footer__title">Resources</h1>
            <ul className="page-footer__nav-list">
              <li>
                <TrackedLink type="page-footer__link" to="/leaders" children="Leaders" />
              </li>
              <li>
                <TrackedLink type="page-footer__link" to="/brand" children="Branding" />
              </li>
              <li>
                <TrackedLink rel="noreferrer" target="_blank" type="page-footer__link" to="https://part.ie/pledge" children="Pledge" />
              </li>
              <li>
                <TrackedLink type="page-footer__link" to="/contact" children="Contact" />
              </li>
              <li>
                <TrackedLink rel="noreferrer" target="_blank" type="page-footer__link" to="https://support.partie.com" children="Help &amp; Support" />
              </li>
            </ul>
          </div>

          <div className="page-footer__nav-column">
            <h1 className="page-footer__title">Policies</h1>
            <ul className="page-footer__nav-list">
              <li>
                <TrackedLink rel="noreferrer" target="_blank" type="page-footer__link" to="https://support.partie.com/hc/en-us/articles/360042045071-Terms-of-Use" children="Terms of Use" />
              </li>
              <li>
                <TrackedLink rel="noreferrer" target="_blank" type="page-footer__link" to="https://support.partie.com/hc/en-us/articles/360041647532-Privacy-Policy" children="Privacy Policy" />
              </li>
              <li>
                <TrackedLink rel="noreferrer" target="_blank" type="page-footer__link" to="https://support.partie.com/hc/en-us/articles/360053679991-General-Rules-Bounties-Challenges-and-Similar-Contests" children="Contests" />
              </li>
              <li>
                <TrackedLink rel="noreferrer" target="_blank" type="page-footer__link" to="https://support.partie.com/hc/en-us/articles/360042504712-Community-Guidelines" children="Guidelines" />
              </li>
            </ul>
          </div>
        </nav>

        <div className="page-footer__secondary-links">
          <Link
            aria-label="Partie Matchmaking Home"
            className="page-footer__home-link"
            to="/"
            onMouseEnter={handleMainLinkMouseEnter}
          >
            <LogoColorBlock backgroundFill={MAIN_LINK_FILLS[currFillIndex]} />
          </Link>

          <div className="page-footer__social-links">
            <ul className="page-footer__social-links-list">
              <li>
                <TrackedLink
                  label="Partie Matchmaking Facebook"
                  rel="noreferrer"
                  target="_blank"
                  type="page-footer__link"
                  to="https://www.facebook.com/partie"
                  children={<FacebookLogo />}
                />
              </li>
              <li>
                <TrackedLink
                  label="Partie Matchmaking Twitter"
                  rel="noreferrer"
                  target="_blank"
                  type="page-footer__link"
                  to="https://twitter.com/partie"
                  children={<TwitterLogo />}
                />
              </li>
              <li>
                <TrackedLink
                  label="Partie Matchmaking Instagram"
                  rel="noreferrer"
                  target="_blank"
                  type="page-footer__link"
                  to="https://www.instagram.com/partie"
                  children={<InstagramLogo />}
                />
              </li>
              <li>
                <TrackedLink
                  label="Partie Matchmaking Youtube"
                  rel="noreferrer"
                  target="_blank"
                  type="page-footer__link"
                  to="https://www.youtube.com/partie"
                  children={<YoutubeLogo />}
                />
              </li>
              <li>
                <TrackedLink
                  label="Partie Matchmaking Twitch"
                  rel="noreferrer"
                  target="_blank"
                  type="page-footer__link"
                  to="https://www.twitch.tv/partie"
                  children={<TwitchLogo />}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
